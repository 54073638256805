
import { computed, defineComponent, onMounted, ref, useSlots } from "vue";
import { useI18n } from "vue-i18n/index";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ElCurrencyInputNoSymbol from "@/components/financial/CurrencyInputNoSymbol.vue";
import ElCurrencyInput from "@/components/financial/CurrencyInput.vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { useForm } from "vee-validate";

interface personalLoan {
  balance: string;
  downPayment: string;
  loanPeriod: number;
  interestRate: number;
  installment_type: string;
  stepUp: {
    totalPeriod: number;
    totalFrequency: number;
    monthlyInstallments: number[];
    monthlyList: number[];
  };
}

export default defineComponent({
  name: "personal Loan",
  components: {
    ErrorMessage,
    Field,
    Form,
    // ElCurrencyInputNoSymbol,
    ElCurrencyInput,
  },
  data() {
    const route = useRoute();
    const monthlyPrincipal = 0;
    const monthlyInterest = 0;
    const monthlyRepayment = 0;
    const yearlyPrincipal = 0;
    const yearlyInterest = 0;
    const yearlyRepayment = 0;
    const totalInterest = 0;
    const submitButton1 = ref<HTMLElement | null>(null);
    const personalLoanCalculated = false;

    const monthlyPaymentSchedule = {
      month: "",
      repayment: 0,
      principal: 0,
      interest: 0,
      balance: 0,
      cumulativeInterest: 0,
    };

    const yearlyPaymentSchedule = {
      year: "",
      repayment: 0,
      principal: 0,
      interest: 0,
      balance: 0,
      cumulativeInterest: 0,
    };

    const paymentSchedule = {
      // principal: 0,
      year: "",
      interest: 0,
      repayment: 0,
      balance: 0,
    };

    const stepUpPaymentSchedule = {
      principal: 0,
      month: "",
      interest: 0,
      repayment: 0,
      balance: 0,
      remainingBalance: 0,
      endStop: false,
    };

    const paymentScheduleYearly = [];
    const stepUpPaymentFrequency = [];
    const stepUpPaymentScheduleMonthly = [];

    const totalPeriods = [
      {
        value: 24,
        label: "24",
      },
      {
        value: 48,
        label: "48",
      },
    ];

    const paymentScheduleMonthly = [];
    return {
      id: route.params.id ?? null,
      store: useStore(),
      monthlyPrincipal,
      monthlyInterest,
      monthlyRepayment,
      yearlyPrincipal,
      yearlyInterest,
      yearlyRepayment,
      submitButton1,
      totalInterest,
      monthlyPaymentSchedule,
      paymentScheduleMonthly,
      yearlyPaymentSchedule,
      personalLoan: {
        balance: "15000",
        downPayment: "0",
        loanPeriod: 10,
        interestRate: 4,
        installment_type: "stepup",
        stepUp: {
          totalPeriod: 24,
          totalFrequency: 5,
          monthlyInstallments: [100, 150, 175, 200, 225],
          monthlyList: [],
        },
      },
      paymentSchedule,
      paymentScheduleYearly,
      personalLoanCalculated,
      totalPeriods,
      stepUpPaymentSchedule,
      stepUpPaymentFrequency,
      stepUpPaymentScheduleMonthly,
    };
  },
  setup() {
    const { t, te, n } = useI18n();

    const personalLoanValidator = Yup.object({
      // name: Yup.string().required().label("Name"),
      installment_type: Yup.string().required().label("Installment Type"),
      raw: Yup.object({
        balance: Yup.string().required().label("Balance"),
        // downPayment: Yup.string().required().label("Down payment"),
        loanPeriod: Yup.number().required().label("Loan Period"),
        interestRate: Yup.number().required().label("Interest Rate"),
      }),
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Personal Loan", ["DMP Calculators"]);
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const nFormat = (data) => {
      return n(Number(data), "currency", "en-MY");
    };

    const activeName = ref("stepUp1");

    const handleClick = (tab: string, event: Event) => {
      console.log(tab, event);
    };

    return {
      personalLoanValidator,
      translate,
      nFormat,
      activeName,
      handleClick,
    };
  },
  methods: {
    init() {
      if (this.id) {
        this.store.dispatch(Actions.GET_CALCULATOR, this.id).then(() => {
          this.personalLoan = this.store.getters.getCalculatorData.raw;
          this.personalLoan.installment_type = this.store.getters.getCalculatorData.installment_type;
          this.personalLoan.stepUp = this.store.getters.getCalculatorData.raw.stepUp;
          this.personalLoan.balance = this.personalLoan.balance.replace(
            ",",
            ""
          );
          this.calculate();
        });
      }
    },
    calculate() {
      this.paymentScheduleYearly = [];
      this.paymentScheduleMonthly = [];
      this.stepUpPaymentFrequency = [];
      this.stepUpPaymentScheduleMonthly = [];

      const loanAmount =
        parseFloat(this.personalLoan.balance) -
        parseFloat(this.personalLoan.downPayment);
      const loanPeriod = this.personalLoan.loanPeriod;
      const interestRate = this.personalLoan.interestRate;

      if (this.personalLoan.installment_type == "single") {
        const loanAmount = parseFloat(this.personalLoan.balance);
        const loanPeriod = this.personalLoan.loanPeriod;
        const monthlyLoanPeriod = loanPeriod * 12;
        const interestRate = this.personalLoan.interestRate;

        const totalInterest = (interestRate / 100) * loanAmount * loanPeriod;
        const totalLoanPlusInterest = loanAmount + totalInterest;
        const monthlyInterest = totalInterest / monthlyLoanPeriod;
        const monthlyInstallment = totalLoanPlusInterest / monthlyLoanPeriod;
        const monthlyPrincipal = monthlyInstallment - monthlyInterest;

        this.monthlyInterest = parseFloat(monthlyInterest.toFixed(2));
        this.monthlyPrincipal = parseFloat(monthlyPrincipal.toFixed(2));

        let totalYearlyPrincipal = 0;
        let totalYearlyInterest = 0;
        let totalYearlyRepayment = 0;

        let yearCount = 0;
        let monthCount = 0;
        let interest = 0;
        let endingBalance = loanAmount;
        let principal = 0;
        let cumulativeInterest = 0;
        const P = loanAmount;
        const r = interestRate / 100 / 12;
        const n = monthlyLoanPeriod;
        const no1 = P * r;
        const no2 = Math.pow(1 + r, n);
        const no3 = no2 - 1;

        const m = (no1 * no2) / no3;
        this.monthlyRepayment = parseFloat(m.toFixed(2));

        for (let m = 0; m < monthlyLoanPeriod; m++) {
          interest = (endingBalance * (interestRate / 100)) / 12;
          interest = parseFloat(interest.toFixed(2));
          principal = this.monthlyRepayment - interest;
          principal = parseFloat(principal.toFixed(2));
          endingBalance -= principal;
          cumulativeInterest += interest;

          this.monthlyPaymentSchedule = {
            month: this.ordinal_suffix_of(m + 1),
            repayment: this.monthlyRepayment,
            principal: principal,
            interest: interest,
            balance: endingBalance < 0 ? 0 : endingBalance,
            cumulativeInterest: cumulativeInterest,
          };

          this.paymentScheduleMonthly.push(this.monthlyPaymentSchedule);

          totalYearlyPrincipal += principal;
          totalYearlyInterest += interest;
          totalYearlyRepayment += this.monthlyRepayment;
          this.totalInterest = totalYearlyInterest;

          monthCount += 1;
          if (monthCount == 12) {
            yearCount += 1;

            this.yearlyPaymentSchedule = {
              year: this.ordinal_suffix_of(yearCount),
              repayment: totalYearlyRepayment,
              principal: totalYearlyPrincipal,
              interest: totalYearlyInterest,
              balance: endingBalance < 0 ? 0 : endingBalance,
              cumulativeInterest: cumulativeInterest,
            };

            this.paymentScheduleYearly.push(this.yearlyPaymentSchedule);

            monthCount = 0;
          }
        }

        this.personalLoanCalculated = true;
      } else {
        this.calculateStepUp(loanAmount, loanPeriod, interestRate);
        this.personalLoanCalculated = true;
      }
      // if (submitButton1.value) {
      //   // Activate indicator
      //   submitButton1.value.setAttribute("data-kt-indicator", "on");

      //   setTimeout(() => {
      //     submitButton1.value?.removeAttribute("data-kt-indicator");
      //   }, 2000);
      // }
    },
    updateStepUpFrequency() {
      this.personalLoan.stepUp.totalFrequency = Math.round(
        this.personalLoan.loanPeriod /
          (this.personalLoan.stepUp.totalPeriod / 12)
      );
      this.calculateStepUpMonthlyInstallments();
    },
    calculateStepUpMonthlyInstallments() {
      let totalFreq = this.personalLoan.stepUp.totalFrequency;
      let monthlyInstallment = 0;
      this.personalLoan.stepUp.monthlyInstallments = [];
      for (let i = 0; i < totalFreq; i++) {
        this.personalLoan.stepUp.monthlyInstallments.push(monthlyInstallment);
      }
    },
    calculateStepUp(loanAmount, loanPeriod, interestRate) {
      let totalFreq = this.personalLoan.stepUp.totalFrequency;
      let totalPeriod = this.personalLoan.stepUp.totalPeriod;
      let balance = loanAmount;
      let remainingBalance = balance;
      let monthsCount = 1;
      let balanceMonths = this.personalLoan.loanPeriod * 12;
      let endDone = false;

      for (let index = 0; index < totalFreq; index++) {
        let isLast = totalFreq - index;
        this.stepUpPaymentScheduleMonthly = [];
        let totalMonthLoop = totalPeriod;
        if (isLast == 1) {
          totalMonthLoop = balanceMonths;
        }
        if (remainingBalance < 0) {
          break;
        }
        for (let stepUpIndex = 0; stepUpIndex < totalMonthLoop; stepUpIndex++) {
          let repayment = this.personalLoan.stepUp.monthlyInstallments[index];
          let interest = (balance * interestRate) / 100 / 12;
          let principal = repayment - interest;
          remainingBalance = balance + interest - repayment;
          let end = false;
          if (remainingBalance < 0 && !endDone) {
            endDone = true;
            end = true;
            break;
          }

          this.stepUpPaymentSchedule = {
            principal: parseFloat(principal.toFixed(2)),
            month: this.ordinal_suffix_of(monthsCount),
            interest: parseFloat(interest.toFixed(2)),
            repayment: parseFloat(repayment.toFixed(2)),
            balance: parseFloat(balance.toFixed(2)),
            remainingBalance: parseFloat(remainingBalance.toFixed(2)),
            endStop: end,
          };

          this.stepUpPaymentScheduleMonthly.push(this.stepUpPaymentSchedule);

          balance = remainingBalance;
          monthsCount++;
        }
        if (isLast !== 1) {
          balanceMonths = balanceMonths - totalPeriod;
        }

        this.stepUpPaymentFrequency.push(this.stepUpPaymentScheduleMonthly);
      }
      console.log(this.stepUpPaymentFrequency);
    },
    ordinal_suffix_of(i) {
      var j = i % 10,
        k = i % 100;
      if (j == 1 && k != 11) {
        return i + "st";
      }
      if (j == 2 && k != 12) {
        return i + "nd";
      }
      if (j == 3 && k != 13) {
        return i + "rd";
      }
      return i + "th";
    },

    onSubmit(values) {
      console.log(values);
      this.store.dispatch(Actions.STORE_CALCULATOR, values).then(() => {
        Swal.fire({
          title: "Congratulation!",
          text: "Calculator has been saved!",
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      });
    },
  },
  async mounted() {
    this.init();
  },
});
